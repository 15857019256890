<ng-container *ngIf="pagination">
    <p-paginator
        (onPageChange)="onPageChange($event)"
        [ngClass]="{'disabled': loading, small: small}"
        [rows]="pagination.per_page"
        [templateLeft]="details"
        [totalRecords]="pagination.total"
        [first]="pagination.from - 1"
        [pageLinkSize]="small ? 1 : 5"
        [rowsPerPageOptions]="small ? [10] : pageSizeOptions"
        [showFirstLastIcon]="!small"
        [showJumpToPageDropdown]="false"
        [showJumpToPageInput]="false"
        dropdownAppendTo="body"
        class="qwyk-pagination">
        <ng-template #details>
            <span *ngIf="!small" class="small text-muted paginator-details">
                <ng-container *ngIf="pagination.from !== null && pagination.to !== null">
                    {{ pagination.from || 0 }} {{ 'common.to_pagination' | translate }} {{ pagination.to }} {{ 'common.of' | translate }}
                </ng-container>
                {{ pagination.total || 0 }} {{ resource }} {{ 'common.found' | translate }}
                <a
                    href="javascript:void(0);"
                    (click)="onPageChange({page: pagination.current_page - 1, rows: pagination.per_page})">{{ 'common.refresh' | translate }}</a>
            </span>
        </ng-template>
    </p-paginator>
</ng-container>

