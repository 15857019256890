import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as QuotationsActions from './quotations.actions';
import {catchError, map, switchMap, take, tap, withLatestFrom,} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {QuotationsServiceBase} from '../quotations-service-base';
import {Store} from '@ngrx/store';
import * as fromQuotations from './quotations.reducer';
import * as QuotationsSelectors from './quotations.selectors';
import {refreshActivities, resetActivities} from '@qwyk/shared-stores/activities';
import * as saveAs from 'file-saver';

@Injectable()
export class QuotationsEffects {
    loadQuotations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationsActions.loadQuotations),
            withLatestFrom(
                this.store.select(QuotationsSelectors.getQuotationsQuery)
            ),
            fetch({
                run: (action, query) => 
                    this.service.getQuotations({...action.query, ...query})
                    .pipe(
                        take(1),
                        map(quotations => 
                            QuotationsActions.loadQuotationsSuccess({
                                quotations: quotations.data,
                                pagination: quotations.meta
                            })
                        )
                    ),
                onError:(action, error) => {
                    return QuotationsActions.loadQuotationsFailure({error})
                }
                
            })
        )
    );

    loadQuotation = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationsActions.loadQuotation),
            switchMap(action =>
                this.service.getQuotation(action.id).pipe(
                    map(quotation =>
                        QuotationsActions.loadQuotationSuccess({
                            quotation,
                        })
                    ),
                    catchError(error =>
                        of(QuotationsActions.loadQuotationFailure({error}))
                    )
                )
            )
        )
    );

    loadQuotationsError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuotationsActions.loadQuotationsFailure),
                tap(action => {
                    this.router.navigate([`/error/${action.error.status}`], {
                        queryParams: {
                            source: this.router.url,
                            errorName: action.error.statusText,
                        },
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    quotationChanged$ = createEffect(() => this.actions$.pipe(
        ofType(QuotationsActions.quotationChanged),
        map(() => refreshActivities())
    ))

    unselectQuotation$ = createEffect(() => this.actions$.pipe(
        ofType(QuotationsActions.unselectQuotation),
        map(() => resetActivities())
    ))

    downloadQuotation$ = createEffect(() => this.actions$.pipe(
        ofType(QuotationsActions.downloadQuotation),
        switchMap(action =>
            this.service.downloadQuotation(action.quotation.id, null).pipe(
                map((blob: Blob) => QuotationsActions.downloadQuotationSuccess({
                    quotation: action.quotation,
                    blob
                })),
                catchError(error => of(QuotationsActions.downloadQuotationFailure({error})))
            )
        )
    ));

    downloadQuotationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuotationsActions.downloadQuotationSuccess),
                tap(action => {
                    saveAs(action.blob, `${action.quotation.friendly_id}.pdf`);
                })
            ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private service: QuotationsServiceBase,
        private router: Router,
        private store: Store<fromQuotations.QuotationsPartialState>
    ) {
    }
}
