import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'nameSeparator',
})
export class NameSeparatorPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any[], ...args: any[]): any {
        return value
            .map(item => {
                return args[0] ? item[args[0]] : item.name;
            })
            .join(', ');
    }
}
