<ng-container
    *ngIf="
    {
        user: user$ | async,
        sidebarStyle: sidebarStyle$ | async,
        isMagayaNetwork: isMagayaNetwork$ | async,
        unreadNotifications: unreadNotifications$ | async,
        customLinks: customLinks$ | async,
        design: siteDesign$ | async
    } as vm"
    >
    <header
        class="navbar navbar-light bg-primary navbar-expand-sm py-0 shadow-sm"
        id="topNavbar"
        >
        <div class="container-fluid p-0">
            <div
                class="header-content w-100 d-flex flex-row justify-content-between"
            >
                <div class="title-container d-flex align-items-stretch flex-grow-1">
                    <h1 class="title py-1">
                        <button
                            *ngIf="!design?.links?.alternative_homepage"
                            [routerLink]="['/']"
                            class="btn btn-primary btn-sm"
                        >
                            <ng-container *ngIf="design; else defaultNavbarBrand">
                                <span
                                    [innerHTML]="design?.titles.navbar_brand"
                                ></span>
                            </ng-container>
                            <span
                                *ngIf="ENVIRONMENT.badge"
                                [ngClass]="ENVIRONMENT.badge"
                                class="badge ml-2 rounded-pill"
                                style="font-size: 0.65em !important"
                            >{{ ENVIRONMENT.name }}</span
                            >
                        </button>
                        <a
                            *ngIf="design?.links.alternative_homepage"
                            [href]="design?.links.alternative_homepage"
                            class="btn btn-primary btn-sm"
                        >
                            <ng-container *ngIf="design; else defaultNavbarBrand">
                                <span
                                    [innerHTML]="design?.titles.navbar_brand"
                                ></span>
                            </ng-container>
                            <span
                                *ngIf="ENVIRONMENT.badge"
                                [ngClass]="ENVIRONMENT.badge"
                                class="badge ml-2 rounded-pill"
                                style="font-size: 0.65em !important"
                            >{{ ENVIRONMENT.name }}</span
                            >
                        </a>
                    </h1>

                    <ul class="list-unstyled navbar-sub-nav d-none d-md-flex m-0 ml-1">
                        <li
                            class="dropdown"
                            *ngIf="!features?.disable_booking_tracking?.value &&
                            permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                        >
                            <button
                                [routerLink]="['/my-portal/shipments']"
                                class="btn navbar-button btn-primary btn-sm"
                            >
                                {{'portal.menu.side-menu.shipments' | translate}}
                                <fa-icon
                                    [icon]="['far', 'angle-down']"
                                    class="caret-down ml-1"
                                ></fa-icon>
                            </button>
                        </li>

                        <li class="dropdown">
                            <button
                                [routerLink]="['/my-portal/quotations']"
                                class="btn navbar-button btn-primary btn-sm"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                            >
                                {{'portal.menu.side-menu.quotations' | translate}}
                                <fa-icon
                                    [icon]="['far', 'angle-down']"
                                    class="caret-down ml-1"
                                ></fa-icon>
                            </button>
                        </li>

                        <li class="dropdown" *ngIf="displayCreateAny">
                            <button class="btn navbar-button btn-primary btn-sm" (click)="addDropdown.toggle($event)" pTooltip="New">
                                <fa-icon
                                    [icon]="['fas', 'plus-square']"
                                    class="mr-2"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="['far', 'angle-down']"
                                    class="caret-down"
                                ></fa-icon>
                            </button>
                            <p-menu #addDropdown appendTo="body" [model]="addMenuItems" [popup]="true"></p-menu>
                        </li>

                        <li class="dropdown" *ngIf="vm.customLinks?.length">
                            <button
                                class="btn navbar-button btn-primary btn-sm"
                                (click)="customLinksDropdown.toggle($event)"
                            >
                                {{ vm.design.links.homepage.header_label }}
                                <fa-icon
                                    [icon]="['far', 'angle-down']"
                                    class="caret-down ml-1"
                                ></fa-icon>
                            </button>
                            <p-menu #customLinksDropdown appendTo="body" [baseZIndex]="25" [model]="vm.customLinks" [popup]="true"></p-menu>
                        </li>
                    </ul>
                </div>

                <div class="navbar-collapse collapse align-items-stretch flex-grow-0">
                    <ul class="nav navbar-nav d-flex navbar-sub-nav w-100">
                        <li *ngIf="ENVIRONMENT.flags.enableHelp" class="dropdown">
                            <button
                                class="btn navbar-button btn-primary btn-sm btn-icon"
                                (click)="helpOP.toggle($event)"
                            >
                                <fa-icon
                                    [icon]="['fas', 'question-circle']"
                                ></fa-icon>
                            </button>
                            <p-overlayPanel #helpOP [baseZIndex]="25" [styleClass]="'custom-overlaypanel'">
                                <ul class="list-group list-group-flush">
                                    <a
                                        (click)="
                                            onboardingService.startOnboarding()
                                        "
                                        *ngIf="ENVIRONMENT.flags.enableOnboarding"
                                        class="list-group-item list-group-item-action"
                                        href="javascript:void(0)"
                                    >
                                        {{'portal.menu.side-menu.welcome' | translate}}
                                    </a>
                                    <a
                                        class="list-group-item list-group-item-action"
                                        href="https://docs.qwykportals.com/"
                                        target="_blank"
                                    >
                                        {{'portal.menu.side-menu.about-portals' | translate}}
                                    </a>
                                    <a
                                        class="list-group-item list-group-item-action"
                                        href="https://docs.qwykportals.com/getting-started/"
                                        target="_blank"
                                    >
                                        {{'portal.menu.side-menu.quickstart' | translate}}
                                    </a>
                                    <a
                                        class="list-group-item list-group-item-action"
                                        href="https://docs.qwykportals.com/features"
                                        target="_blank"
                                    >
                                        {{'portal.menu.side-menu.help-with-features' | translate}}
                                    </a>
                                    <a
                                        class="list-group-item list-group-item-action"
                                        href="https://docs.qwykportals.com/resources/change-log"
                                        target="_blank"
                                    >
                                        {{'portal.menu.side-menu.see-whats-new' | translate}}
                                    </a>
                                </ul>
                            </p-overlayPanel>
                        </li>

                        <li class="dropdown">
                            <button
                                [routerLink]="['/my-portal', 'notifications']"
                                class="btn navbar-button btn-primary btn-sm btn-icon"
                                pTooltip="{{'portal.menu.side-menu.notifications' | translate}}"
                            >
                                <fa-layers [fixedWidth]="true">
                                    <fa-icon [icon]="['fas', 'bell']"></fa-icon>
                                    <fa-layers-counter
                                        *ngIf="vm.unreadNotifications"
                                        class="fa-icon-counter"
                                        content="{{ vm.unreadNotifications }}"
                                    >
                                    </fa-layers-counter>
                                </fa-layers>
                            </button>
                        </li>

                        <li
                            *ngIf="vm.user"
                            class="dropdown"
                        >
                            <button
                                class="btn navbar-button btn-primary btn-sm avatar"
                                (click)="userOP.toggle($event)"
                            >
                                <span
                                    class="text-color-contrast-secondary bg-secondary"
                                >{{ vm.user.name[0] | uppercase }}</span
                                >
                                <fa-icon
                                    [icon]="['far', 'angle-down']"
                                    class="caret-down ml-1"
                                ></fa-icon>
                            </button>
                            <p-overlayPanel #userOP [baseZIndex]="25" [styleClass]="'custom-overlaypanel'">
                                <ng-template pTemplate="content">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item border-bottom">
                                            <div class="font-weight-bold">
                                                {{ vm.user.name }}
                                            </div>
                                            <div>{{ vm.user.email }}</div>
                                        </li>
                                        <hr class="m-0 border-0"/>
                                        <button
                                            [routerLink]="[
                                                '/my-portal/settings/profile'
                                            ]"
                                            class="list-group-item list-group-item-action border-0"
                                            type="button"
                                        >
                                            <fa-icon
                                                [fixedWidth]="true"
                                                [icon]="['far', 'user']"
                                                class="mr-1"
                                            ></fa-icon>
                                            {{'portal.menu.side-menu.profile' | translate}}
                                        </button>
                                        <button *ngIf="!vm.isMagayaNetwork"
                                                [routerLink]="[
                                                '/my-portal/settings/profile'
                                            ]"
                                                class="list-group-item list-group-item-action"
                                                fragment="changePassword"
                                                type="button"
                                        >
                                            <fa-icon
                                                [fixedWidth]="true"
                                                [icon]="['far', 'lock']"
                                                class="mr-1"
                                            ></fa-icon>
                                            {{ 'portal.menu.top-menu.change-password' | translate }}
                                        </button>
                                        <button
                                            (click)="logout()"
                                            class="list-group-item list-group-item-action"
                                            type="button"
                                        >
                                            <fa-icon
                                                [fixedWidth]="true"
                                                [icon]="['far', 'power-off']"
                                                class="mr-1"
                                            ></fa-icon>
                                            {{ 'portal.menu.top-menu.log-out' | translate }}
                                        </button>
                                    </ul>
                                </ng-template>
                            </p-overlayPanel>
                        </li>
                    </ul>
                </div>

                <ul class="list-unstyled navbar-sub-nav d-flex d-sm-none m-0 ml-1">
                    <li
                        *ngIf="vm.user"
                        class="dropdown"
                    >
                        <button
                            class="btn navbar-button btn-primary btn-sm avatar"
                            (click)="userOP2.toggle($event)"
                            >
                            <span
                                class="text-color-contrast-secondary bg-secondary"
                            >{{ vm.user.name[0] | uppercase }}</span
                            >
                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>
                        <p-overlayPanel #userOP2 [styleClass]="'custom-overlaypanel'">
                            <ng-template pTemplate="content">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item border-bottom">
                                        <div class="font-weight-bold">
                                            {{ vm.user.name }}
                                        </div>
                                        <div>{{ vm.user.email }}</div>
                                    </li>
                                    <hr class="m-0 border-0"/>
                                    <button
                                        [routerLink]="[
                                            '/my-portal/settings/profile'
                                        ]"
                                        class="list-group-item list-group-item-action border-0"
                                        type="button"
                                    >
                                        <fa-icon
                                            [fixedWidth]="true"
                                            [icon]="['far', 'user']"
                                            class="mr-1"
                                        ></fa-icon>
                                        {{'portal.menu.side-menu.profile' | translate}}
                                    </button>
                                    <button *ngIf="!vm.isMagayaNetwork"
                                            [routerLink]="['/my-portal/settings/profile']"
                                            class="list-group-item list-group-item-action"
                                            fragment="changePassword"
                                            type="button"
                                    >
                                        <fa-icon
                                            [fixedWidth]="true"
                                            [icon]="['far', 'lock']"
                                            class="mr-1"
                                        ></fa-icon>
                                        {{ 'portal.menu.top-menu.change-password' | translate }}
                                    </button>
                                    <button
                                        (click)="logout()"
                                        class="list-group-item list-group-item-action"
                                        type="button"
                                    >
                                        <fa-icon
                                            [fixedWidth]="true"
                                            [icon]="['far', 'power-off']"
                                            class="mr-1"
                                        ></fa-icon>
                                        {{ 'portal.menu.top-menu.log-out' | translate }}
                                    </button>
                                </ul>
                            </ng-template>
                        </p-overlayPanel>
                    </li>
                </ul>
            </div>
        </div>
    </header>

    <div class="page">
        <div
            [ngClass]="{ 'nav-sidebar-collapsed': vm.sidebarStyle }"
            class="nav-sidebar border-right d-flex flex-column justify-content-between shadow-sm"
            id="navSidebar"
        >
            <div class="nav-sidebar-inner-scroll">
                <nav class="nav nav-top-level-items flex-column">
                    <li
                        [routerLinkActiveOptions]="{ exact: false }"
                        [routerLink]="['/my-portal/dashboard']"
                        class="nav-item"
                        routerLinkActive="active"
                    >
                        <a class="nav-link">
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'tachometer-alt-fast']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.dashboard' | translate}}</span>
                        </a>
                        <ul
                            class="nav nav-inner-level-items flex-column only-collapsed"
                        >
                            <li class="nav-item nav-inner-level-header d-none bold">
                                <a class="nav-link"> {{'portal.menu.side-menu.dashboard' | translate}} </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        routerLinkActive="active"
                        *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                    >
                        <a
                            [routerLink]="['/my-portal', 'quotations']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'hands-usd']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.quotations' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'quotations']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.quotations' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.CREATE_QUOTATIONS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'booking-wizard']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.create-quotation' | translate}}
                                </a>
                            </li>
                            <!-- <li class="nav-item" routerLinkActive="active" style="cursor:wait">
                                <a class="nav-link disabled" [routerLink]="['/my-portal', 'quotations', 'history']">
                                    Bookmarks <span class="badge badge-secondary py-0">Coming soon</span>
                                </a>
                            </li> -->
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'quotations']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.list' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        routerLinkActive="active"
                        *ngIf="vm.isMagayaNetwork && displayMainWarehouseLink"
                    >
                        <a
                            [routerLink]="['/my-portal', resolveDefaultWarehouseRoute()]"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'warehouse']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.warehouse'| translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'cargo-detail']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.warehouse'| translate}}
                                </a>
                            </li>


                            <li
                                class="nav-item"
                                routerLinkActive="active"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'cargo-detail']"
                                    class="nav-link"
                                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_COMMODITIES)"
                                >
                                    {{'portal.menu.side-menu.cargo-detail' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_CARGORELEASES)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'cargo-releases']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.cargo-releases' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVENTORY)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'warehouse-inventory']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.inventory' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PICKUPORDERS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'pickup-orders']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.pickup-orders'| translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_WAREHOUSERECEIPTS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'warehouse-receipts']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.warehouse-receipts' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"
                        routerLinkActive="active"
                        *ngIf="!features?.disable_booking_tracking?.value &&
                            permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                    >
                        <a
                            [routerLink]="['/my-portal', 'shipments']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'box-alt']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.shipments' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                                *ngIf="!features?.disable_booking_tracking?.value &&
                                        permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'shipments']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.shipments' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="!features?.disable_booking_tracking?.value &&
                                    permissions.includes(customerPermissionsEnum.CREATE_BOOKINGS)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'booking',
                                        'new',
                                        'choose'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.create-shipment' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'shipments',
                                        'list'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.list' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        routerLinkActive="active"
                        *ngIf="ENVIRONMENT.flags.enablePurchaseOrders && permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                    >
                        <a [routerLink]="['/my-portal', 'orders']" class="nav-link">
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'shopping-cart']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.purchase-orders' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'orders']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.purchase-orders' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'orders', 'list']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.list' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a
                            [routerLink]="['/my-portal', 'schedules']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'clock']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.schedules' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'schedules']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.schedules' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active">
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'schedules',
                                        'search'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.search' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active">
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'schedules',
                                        'saved'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.list' | translate}}
                                </a>
                            </li>
                            <!-- <li class="nav-item" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/my-portal', 'schedules', 'history']">
                                    History
                                </a>
                            </li> -->
                        </ul>
                    </li>
                    <li *ngIf="vm.isMagayaNetwork && displayBilling" class="nav-item" routerLinkActive="active">
                        <a
                            [routerLink]="['/my-portal', permissions.includes(customerPermissionsEnum.VIEW_INVOICES) ? 'invoices' : 'payments']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'money-check']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.billing' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                            >
                                <a
                                    [routerLink]="['/my-portal', permissions.includes(customerPermissionsEnum.VIEW_INVOICES) ? 'invoices' : 'payments']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.billing' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVOICES)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'invoices',
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.invoices' | translate}}
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PAYMENTS)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'payments',
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.payments' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a
                            [routerLink]="['/my-portal', 'reports']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'chart-bar']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.reports'|translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">

                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'reports']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.reports'|translate}}
                                </a>
                            </li>
                            <ng-container *ngIf="vm.isMagayaNetwork">
                                <li class="nav-item" routerLinkActive="active"
                                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_CARGORELEASES)"
                                >
                                    <a
                                        [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'cargo-releases'
                                    ]"
                                        class="nav-link"
                                    >
                                        {{'portal.menu.side-menu.cargo-releases' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item" routerLinkActive="active"
                                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVOICES)"
                                >
                                    <a
                                        [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'invoices'
                                    ]"
                                        class="nav-link"
                                    >
                                        {{'portal.menu.side-menu.invoices' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item" routerLinkActive="active"
                                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PICKUPORDERS)"
                                >
                                    <a
                                        [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'pickup-orders'
                                    ]"
                                        class="nav-link"
                                    >
                                        {{'portal.menu.side-menu.pickup-orders' | translate}}
                                    </a>
                                </li>
                            </ng-container>
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'quotations'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.quotations' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active">
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'schedules'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.schedules' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="!features?.disable_booking_tracking?.value &&
                                    permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                            >
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'shipments'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.shipments' | translate}}
                                </a>
                            </li>

                            <ng-container *ngIf="vm.isMagayaNetwork">
                                <li class="nav-item" routerLinkActive="active"
                                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_WAREHOUSERECEIPTS)"
                                >
                                    <a
                                        [routerLink]="[
                                        '/my-portal',
                                        'reports',
                                        'warehouse-receipts'
                                    ]"
                                        class="nav-link"
                                    >
                                        {{'portal.menu.side-menu.warehouse-receipts' | translate}}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                    <li
                        [routerLinkActiveOptions]="{ exact: true }"
                        [routerLink]="['/my-portal/notifications']"
                        class="nav-item"
                        routerLinkActive="active"
                    >
                        <a class="nav-link">
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'bell']"
                            ></fa-icon>
                            <div
                                class="nav-link-text d-flex justify-content-between flex-grow-1"
                            >
                                {{'portal.menu.side-menu.notifications' | translate}}
                                <span
                                    *ngIf="vm.unreadNotifications"
                                    class="badge text-bg-light px-2 py-0 rounded-pill"
                                    style="background-color: rgba(0, 0, 0, 0.1); margin: auto 0;"
                                >
                                    {{ vm.unreadNotifications }}
                                </span>
                            </div>
                        </a>
                        <ul
                            class="nav nav-inner-level-items flex-column only-collapsed"
                        >
                            <li class="nav-item nav-inner-level-header d-none bold">
                                <a class="nav-link"> {{'portal.menu.side-menu.notifications' | translate}} </a>
                            </li>
                        </ul>
                    </li>
                    <ng-container *ngIf="features$ | async; let features">
                        <li class="nav-item" routerLinkActive="active"
                        *ngIf="!features.disable_global_chat?.value">
                        <a
                            [routerLink]="['/my-portal', 'conversations']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'comments']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.chat' | translate}}</span>
                        </a>
                    </li>
                    </ng-container>
                    <li class="nav-item" routerLinkActive="active">
                        <a
                            [routerLink]="['/my-portal', 'settings']"
                            class="nav-link"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="['far', 'cog']"
                            ></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.settings' | translate}}</span>
                        </a>
                        <ul class="nav nav-inner-level-items flex-column">
                            <li
                                class="nav-item nav-inner-level-header d-none border-bottom bold"
                            >
                                <a
                                    [routerLink]="['/my-portal', 'settings']"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.settings' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active">
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'settings',
                                        'profile'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.profile' | translate}}
                                </a>
                            </li>
                            <!-- <li class="nav-item" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/my-portal', 'settings', 'team']">
                                    Team
                                </a>
                            </li> -->
                            <li class="nav-item" routerLinkActive="active">
                                <a
                                    [routerLink]="[
                                        '/my-portal',
                                        'settings',
                                        'notifications'
                                    ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.notifications' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>

                </nav>
            </div>
            <div class="nav-sidebar-collapse-button overflow-hidden">
                <ul class="nav flex-column">
                    <li class="nav-item border-top">
                        <a (click)="toggleSidebar()" class="nav-link">
                            <fa-icon [icon]="['far', 'arrow-from-right']"></fa-icon>
                            <span class="nav-link-text">{{'portal.menu.side-menu.collapse' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <section
            [ngClass]="{ expanded: vm.sidebarStyle }"
            class="page-container"
        >
            <router-outlet></router-outlet>
        </section>
    </div>
</ng-container>

<ng-template #defaultNavbarBrand>Portal</ng-template>

<p-confirmDialog #cd [style]="{width: '498px'}">
    <ng-template pTemplate="header">
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" (click)="cd.accept()" class="btn btn-primary">{{ 'common.continue' | translate }}</button>
        <button type="button" (click)="cd.reject()" class="btn btn-light">{{ 'common.cancel' | translate }}</button>
    </ng-template>
</p-confirmDialog>
