import Pusher from 'pusher-js';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'develop',
    url: 'http://localhost:4200',
    badge: 'badge-warning',
    sentry: {
        enabled: false,
        dsn: 'https://ea089f67cc2643ed89a8db0135fbc0ef@sentry.io/4825874',
        traceOrigins: ['http://localhost:4200', 'http://hub2.test'],
        tracesSampleRate: 1.0,
    },
    debug: true,
    production: false,
    backendServer: 'https://hub-server.test',
    schedulesBackend: 'http://schedules2.test',
    portalSitesMask: 'http://{0}.localhost:4201',
    feedbackSite: 'https://magaya.ideas.aha.io',
    auth0: {
        domain: 'magaya-prod.us.auth0.com',
        clientId: 'uRiAi2o1plD9O5M87pfauPp6hWpe07t7',
        authorizationParams: {
            redirect_uri: 'http://localhost:4200/callback',
            audience: 'https://apps.qwyk.io',
        },
    },
    supportEmail: 'rmsupport@magaya.com',
    utils: {
        Algolia: {
            applicationId: '3G8S1ON0FI',
            adminApiKey: 'c397d91713a36b2e696eb4454f22ec40',
        },
        Echo: {
            userModel: 'App.Models.Hub.Auth.User',
            notificationNamespace: 'App\\Notifications',
            options: {
                broadcaster: 'pusher',
                key: '0c41afe0ea23a0b6294c',
                cluster: 'eu',
                forceTLS: true,

                client: new Pusher('0c41afe0ea23a0b6294c', {
                    cluster: 'eu',
                    enabledTransports: ['ws', 'wss'],
                    authEndpoint: 'http://hub2.test/api/hub/broadcasting/auth',
                }),
            },
        },
        Veem: {
            environment: 'sandbox',
            clientId: 'MagayaDFP-VnqdrKYa9Y',
        },
        Amplitude: {
            enabled: false,
            apiKey: null,
        },
        Segment: {
            apiKey: 'osAGEHvLJxUD8BYvDW0Jjxskut6tYzx1',
            debug: false,
            loadOnInitialization: true,
        },
    },
};
