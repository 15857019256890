import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { combineLatest, Subscription } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

import { LoaderService } from '@qwyk/core';
import { Portals, PortalsAuth } from '@qwyk/models';
import { environment } from '@qwyk/portals/environment';
import { GoogleAnalyticsService } from '@qwyk/utilities';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { AuthenticationFacade } from '@qwyk/portals/authentication';
import { PortalsTranslateService } from '@qwyk/portals/translation';

import { versions } from './../../../environments/versions';

@Component({
    selector: 'qw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'portals-client';
    public VERSIONINFO = versions;
    public DEFAULT_LANGUAGE = environment.translation.defaultLanguage;
    public designCss: SafeResourceUrl;
    public gaTrackingUrl: SafeResourceUrl;
    public auth$ = this.authentication.user$;
    public design$ = this.siteConfig.design$;
    public features$ = this.siteConfig.features$;

    private design: Portals.SiteDesign;
    private designSubscription: Subscription;
    private featuresSubscription: Subscription;

    constructor(
        private sanitizer: DomSanitizer,
        private siteConfig: SiteConfigFacade,
        private translate: PortalsTranslateService,
        private authentication: AuthenticationFacade,
        private googleAnalytics: GoogleAnalyticsService,
        private cookieConsentService: NgcCookieConsentService,
        public loaderService: LoaderService
    ) {
        cookieConsentService.init(this.cookieConsentService.getConfig());
    }

    ngOnInit(): void {
        this.designSubscription = this.design$.subscribe(design => {
            if (design) {
                this.design = design;
                const fontsString = this.makeFontsString(this.design.fonts);
                const colorsString = this.makeColorsString(this.design.colors);
                this.designCss = this.sanitizer.bypassSecurityTrustResourceUrl(
                    `${environment.microservicesServer}/theme.css?${colorsString}&${fontsString}` // FIXME: URL
                );
                if (
                    this.design.analytics &&
                    this.design.analytics.ga_tracking_id
                ) {
                    this.googleAnalytics.initialize(
                        this.design.analytics.ga_tracking_id,
                        true
                    );
                }
            }
        });

        this.featuresSubscription = combineLatest([
            this.features$,
            this.auth$,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ]).subscribe((res: [any, PortalsAuth.User]) => {
            const lang = res[1]?.language;
            if (res[0]?.language_picker?.value) {
                this.translate.init(lang || this.DEFAULT_LANGUAGE);
            } else {
                this.translate.init(lang || this.DEFAULT_LANGUAGE);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.designSubscription) {
            this.designSubscription.unsubscribe();
        }

        if (this.featuresSubscription) {
            this.featuresSubscription.unsubscribe();
        }

        this.googleAnalytics.destruct();
    }

    makeFontsString(fonts: Portals.SiteFonts) {
        return Object.keys(fonts)
            .map(key => `${key}=${fonts[key]}`)
            .join('&');
    }

    makeColorsString(colors: Portals.SiteColors) {
        return Object.keys(colors)
            .map(key => {
                const arg = key.replace('_color', '');
                const color = colors[key].replace('#', '');
                return `${arg}=${color}`;
            })
            .join('&');
    }
}
